import localForage from 'localforage';

export const initializeLocalForage = (): void => {
  if (!process.browser) return;

  localForage.config({
    driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
    name: 'ActionVFX',
    version: 1.0,
    storeName: 'actionvfx',
    description: 'ActionVFX client store'
  });
};
