import {
  Wishlist,
  WishlistProduct,
} from '../../types/state/reducers/wishlistsReducersTypes';

export function convertToWishlistProduct(object: any): WishlistProduct {
  return {
    id: object.id,
    sku: object.sku,
    name: object.name,
    type: object.collection_variants || object.elements ? 'Shoppe::ProductBundle' : 'Shoppe::Product',
    image: object.poster || object.image || object.media?.image,
  };
}

export function convertToWishlist(object: any): Wishlist {
  return {
    id: object.id,
    name: object.name,
    created_at: object.created_at,
    products: object.products || [],
  };
}
