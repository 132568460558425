import {
  ProductVariantsPricesState,
  ProductVariantPrice
} from '../../types/state/reducers/productVariantsPricesTypes';
import { PRODUCT_VARIANTS_PRICES_ACTIONS } from '../../constants/actions';

const initialPrices: Array<Array<ProductVariantPrice>> = [];

const priceVariantsAreEqual = (
  priceVariants1: Array<ProductVariantPrice> = [],
  priceVariants2: Array<ProductVariantPrice> = []
): boolean => {
  return (
    priceVariants1.length === priceVariants2.length &&
    priceVariants1.every(
      (variant: ProductVariantPrice, index: number) => variant.id === priceVariants2[index].id
    )
  );
};

const addPriceVariantsHandler = (
  state: ProductVariantsPricesState,
  priceVariantsToAdd: Array<ProductVariantPrice>
): ProductVariantsPricesState => {
  if (!state.prices) {
    return { prices: [priceVariantsToAdd] };
  }
  for (let i = 0; i < state.prices.length; i++) {
    const priceVariants = state.prices[i];
    if (priceVariantsAreEqual(priceVariants, priceVariantsToAdd)) return state;
  }
  return {
    prices: [...state.prices, priceVariantsToAdd]
  };
};

const removePriceVariantsHandler = (
  state: ProductVariantsPricesState,
  priceVariantsToRemove: Array<ProductVariantPrice>
): ProductVariantsPricesState => {
  return {
    prices: state.prices.filter(
      (priceVariants: Array<ProductVariantPrice>) =>
        !priceVariantsAreEqual(priceVariants, priceVariantsToRemove)
    )
  };
};

export const productVariantsPricesReducer = (
  state: ProductVariantsPricesState = { prices: initialPrices },
  action: {
    productVariantsPrices: Array<ProductVariantPrice>;
    type: string;
  }
) => {
  switch (action.type) {
    case PRODUCT_VARIANTS_PRICES_ACTIONS.ADD_PRODUCT_VARIANTS_PRICES:
      return addPriceVariantsHandler(state, action.productVariantsPrices);
    case PRODUCT_VARIANTS_PRICES_ACTIONS.REMOVE_PRODUCT_VARIANTS_PRICES:
      return removePriceVariantsHandler(state, action.productVariantsPrices);
    case PRODUCT_VARIANTS_PRICES_ACTIONS.CLEAR_ALL_PRODUCTS_VARIANTS_PRICES:
      return { prices: [] };
    default:
      return state;
  }
};
