import { LICENSE_TYPES_CACHE_ACTIONS } from '../../constants/actions';
import { LicenseTypesCacheState } from '../../types/state/reducers/licenseTypesReducersTypes';
import { LicenseTypeDetail } from '../../types/api/LicenseTypesTypes';

const initialState: LicenseTypesCacheState = {
  licenseTypes: null,
};

export const licenseTypesCacheReducer = (
  state: LicenseTypesCacheState = initialState,
  action: {
    type: string,
    licenseTypes: Array<LicenseTypeDetail>,
  },
): LicenseTypesCacheState => {
  switch (action.type) {
    case LICENSE_TYPES_CACHE_ACTIONS.CACHE_LICENSE_TYPES:
      return { licenseTypes: action.licenseTypes };
    default:
      return state;
  }
}
