import { FetchFilterOption } from '../../types/api/FiltersTypes';
import { FiltersCacheState } from '../../types/state/reducers/filtersCache';

import { FILTERS_CACHE_ACTIONS } from '../../constants/actions';

const {
  SET_PFL_FILTERS,
} = FILTERS_CACHE_ACTIONS;

const initialState: FiltersCacheState = {
  pflFilters: [],
};

export const filtersCacheReducer = (
  state: FiltersCacheState = initialState,
  action: {
    type: string,
    payload: Array<FetchFilterOption>,
  }
): FiltersCacheState => {
  const { type, payload } = action;
  switch (type) {
    case SET_PFL_FILTERS:
      return { ...state, pflFilters: payload };
    default:
      return state;
  }
}
