import { composeWithDevTools } from 'redux-devtools-extension';
import { reducers } from './reducers';
import { middleware } from './middleware';
import { initialDispatches } from './dispatches';
import { createStore, Store } from 'redux';
import { ApplicationState } from './../types/state/storeTypes';
const store: Store<ApplicationState> = createStore(reducers, composeWithDevTools(middleware));

initialDispatches(store);

export default store;
