import { WISHLISTS_ACTIONS } from './../../constants/actions';
import {
  WishlistsState,
  Wishlist,
  WishlistProduct,
} from '../../types/state/reducers/wishlistsReducersTypes';
import {
  convertToWishlistProduct,
  convertToWishlist,
} from '../../helpers/models/wishlists';

const initialWishlists: Array<Wishlist> = null;

export const wishlistsReducer = (
  state: WishlistsState = { wishlists: initialWishlists },
  action: {
    type: string,
    wishlists?: Array<Wishlist>,
    wishlist?: Wishlist | any,
    product?: WishlistProduct | any,
    loadingWishlists?: boolean,
  },
): WishlistsState => {
  const wishlistsCopy = state.wishlists ? [...state.wishlists] : [];
  switch (action.type) {
    case WISHLISTS_ACTIONS.SET_WISHLISTS:
      return { ...state, wishlists: action.wishlists || [] };
    case WISHLISTS_ACTIONS.ADD_WISHLIST:
      return { ...state, wishlists: [...state.wishlists, convertToWishlist(action.wishlist) ] };
    case WISHLISTS_ACTIONS.REMOVE_WISHLIST:
      return {
        ...state,
        wishlists: state.wishlists.filter(wishlist => wishlist.id !== action.wishlist.id)
      }
    case WISHLISTS_ACTIONS.UPDATE_WISHLIST:
      const wishlistToUpdate = convertToWishlist(action.wishlist);
      const wishlistToUpdateIndex = state.wishlists.map(wishlist => wishlist.id).indexOf(wishlistToUpdate.id);
      if (wishlistToUpdateIndex < 0) return state;
      wishlistsCopy[wishlistToUpdateIndex] = wishlistToUpdate;
      return {
        ...state, wishlists: wishlistsCopy,
      };
    case WISHLISTS_ACTIONS.ADD_TO_WISHLIST:
      const wishlistToAddTo = convertToWishlist(action.wishlist);
      const productToAdd = convertToWishlistProduct(action.product);
      wishlistToAddTo.products = wishlistToAddTo.products || [];
      wishlistToAddTo.products.push(productToAdd);
      const wishlistToAddToIndex = state.wishlists.map(wishlist => wishlist.id).indexOf(wishlistToAddTo.id);
      if (wishlistToAddToIndex < 0) return state;
      wishlistsCopy[wishlistToAddToIndex] = wishlistToAddTo;
      return {
        ...state, wishlists: wishlistsCopy,
      };
    case WISHLISTS_ACTIONS.REMOVE_FROM_WISHLIST:
      const wishlistToRemoveFrom = convertToWishlist(action.wishlist);
      if (!wishlistToRemoveFrom) return { ...state, wishlists: state.wishlists };
      wishlistToRemoveFrom.products = wishlistToRemoveFrom.products || [];
      wishlistToRemoveFrom.products = wishlistToRemoveFrom.products.filter(product => product.id !== action.product.id);
      const wishlistToRemoveFromIndex = state.wishlists.map(wishlist => wishlist.id).indexOf(wishlistToRemoveFrom.id);
      if (wishlistToRemoveFromIndex < 0) return { ...state, wishlists: state.wishlists };
      wishlistsCopy[wishlistToRemoveFromIndex] = wishlistToRemoveFrom;
      return {
        ...state, wishlists: wishlistsCopy
      };
    default:
      return state;
  }
}
