import { RateLimitingStatusState } from '../../types/state/reducers/rateLimitingStatus';
import { AnyAction } from 'redux';

import { RATE_LIMITING_STATUS_ACTIONS } from '../../constants/actions';
const { VARIANT_DOWNLOADS_LIMIT_REACHED } = RATE_LIMITING_STATUS_ACTIONS;

const initialState: RateLimitingStatusState = {
  variantDownloadsLimitReached: false,
};

export const rateLimitingStatusReducer = (
  state: RateLimitingStatusState = initialState,
  action: AnyAction,
): RateLimitingStatusState => {
  switch (action.type) {
    case VARIANT_DOWNLOADS_LIMIT_REACHED:
      return { ...state, variantDownloadsLimitReached: action.payload }
    default:
      return state;
  };
}
