import { OWNERSHIPS_CACHE_ACTIONS } from '../../constants/actions';
import { OwnershipsCacheState } from '../../types/state/reducers/ownershipsCacheReducersTypes';
import { ProductOwnershipResponse } from '../../types/api/OwnershipTypes';

const initialState: OwnershipsCacheState = { ownerships: {}, notOwnedProductsIds: [] };

export const ownershipsCacheReducer = (
  state: OwnershipsCacheState = initialState,
  action: {
    ownership?: ProductOwnershipResponse,
    ownerships?: { [key: number]: ProductOwnershipResponse },
    notOwnedProductsIds?: Array<number>,
    type: string,
  }
): OwnershipsCacheState => {
  switch (action.type) {
    case OWNERSHIPS_CACHE_ACTIONS.SET_OWNERSHIPS_CACHE:
      return { ...state, ownerships: action.ownerships };
    case OWNERSHIPS_CACHE_ACTIONS.ADD_TO_OWNERSHIPS_CACHE:
      if (!action.ownership || !!state.ownerships[action.ownership.id])
        return state;
      return { ...state, ownerships: {...state.ownerships, [action.ownership.id]: action.ownership}};
    case OWNERSHIPS_CACHE_ACTIONS.ADD_MULTIPLE_TO_OWNERSHIPS_CACHE:
      return { ...state, ownerships: { ...state.ownerships, ...action.ownerships }};
    case OWNERSHIPS_CACHE_ACTIONS.ADD_NOT_OWNED_PRODUCT_ID:
      if (!state.notOwnedProductsIds)
        return { ...state, notOwnedProductsIds: action.notOwnedProductsIds };
      return { ...state, notOwnedProductsIds: [ ...state.notOwnedProductsIds, ...action.notOwnedProductsIds ]};
    default:
      return state;
  }
}
