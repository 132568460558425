import { newState } from '../../helpers/state/reducers/new-state';
import { Message, MessagesState } from '../../types/state/reducers/messagesReducersTypes';
import { MESSAGES_ACTIONS } from './../../constants/actions';

const initialMessages: Message[] = [];

export const messagesReducer = (
  state: MessagesState = { messages: initialMessages },
  action: {
    messages?: Message[];
    type: string;
  }
) => {
  switch (action.type) {
    case MESSAGES_ACTIONS.SET_MESSAGES:
      return { messages: action.messages };
    case MESSAGES_ACTIONS.ADD_MESSAGE:
      if (state.messages == undefined) {
        return { messages: action.messages };
      }
      return {
        messages: action.messages
      };
    case MESSAGES_ACTIONS.CLEAR_MESSAGES:
      return { messages: initialMessages };
    default:
      return state;
  }
};
