export const ENV = {
  environment: process.env.APP_ENV,
  avfxURL: process.env.AVFX_URL,
  api: {
    baseURL: process.env.API_URL
  },
  internalAdsApiUrl: process.env.INTERNAL_ADS_API_URL,
  authReturnTo: process.env.AUTH_RETURN_URL,
  googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
  fbPixelId: process.env.FB_PIXEL_ID,
  fbDomainVerification: process.env.FACEBOOK_DOMAIN_VERIFICATION,
  tapAccountId: process.env.TAPFILIATE_ACCOUNT_ID,
  justunoId: process.env.JUSTUNO_ACCOUNT_NUMBER,
  publicStripeKey: process.env.STRIPE_PUBLISHABLE_KEY,
  appUrl: process.env.APP_URL,
  appEnv: process.env.APP_ENV,
  appName: process.env.APP_NAME,
  uploadsUrl: process.env.UPLOADS_URL,
  displaySaleBanner: process.env.DISPLAY_SALE_BANNER,
  activeCampaignApiKey: process.env.ACTIVE_CAMPAIGN_API_KEY,
  activeCampaignDefaultListId: process.env.ACTIVE_CAMPAIGN_DEFAULT_LIST_ID,
  googleRecaptchaSiteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
  googleRecaptchaSecretKey: process.env.GOOGLE_RECAPTCHA_SECRET_KEY,
  storyblokApiKey: process.env.STORYBLOK_API_KEY,
  hidePaypalForSubscriptionPayments: process.env.HIDE_PAYPAL_FOR_SUBSCRIPTION_PAYMENTS,
  shouldDisplayPasswordProtection: process.env.USE_PASSWORD_PROTECTION,
};
