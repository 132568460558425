import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

import { ApplicationState } from '../../types/state/storeTypes';
import { RateLimitingListenerPluginProps, RateLimitingListenerPluginStateProps } from '../../types/components/rate-limiting/RateLimitingListenerPlugin'

const RateLimitingListenerPlugin: React.FC<any> = (props: RateLimitingListenerPluginProps) => {
  const { rateLimitingStatus } = props;
  const router = useRouter();

  const redirectToRateLimitedPage = () => {
    router.push('/503');
  }

  useEffect(() => {
    if (!rateLimitingStatus) return;
    Object.keys(rateLimitingStatus).forEach(key => {
      if (rateLimitingStatus[key] === true) redirectToRateLimitedPage();
    });
  }, [rateLimitingStatus])

  return <></>
};

const mapStateToProps = (state: ApplicationState): RateLimitingListenerPluginStateProps => ({
  rateLimitingStatus: state.rateLimitingStatus,
});

export default connect(mapStateToProps)(RateLimitingListenerPlugin);
