import { CATEGORIES_CACHE_ACTIONS } from '../../constants/actions';
import { CategoriesCacheState } from '../../types/state/reducers/categoriesCache';
import { CollectionResponse } from '../../types/api/CollectionsIndexTypes';

const initialState: CategoriesCacheState = {};

export const categoriesCacheReducer = (
  state = initialState,
  action: {
    slug: string,
    payload?: {
      collections: Array<CollectionResponse>,
      totalPages: number,
      currentPage: number,
      nextPage: number,
    },
    nextCollections?: Array<CollectionResponse>,
    type: string,
  },
): CategoriesCacheState => {
  switch (action.type) {
    case CATEGORIES_CACHE_ACTIONS.SET_ENTRY:
      state[action.slug] = { ...action.payload };
      return state;
    case CATEGORIES_CACHE_ACTIONS.ADD_PAGE:
      const collectionsObject = state[action.slug];
      const { currentPage, totalPages, nextPage, collections } = collectionsObject;
      const currentPageIncrement = currentPage + 1;
      const nextPageIncrement = currentPageIncrement === totalPages ? null : nextPage + 1;
      state[action.slug] = {
        ...state[action.slug],
        currentPage: currentPageIncrement,
        nextPage: nextPageIncrement,
        collections: (collections || []).concat(action.nextCollections || []),
      };
      return state;
    default:
      return state;
  }
}
