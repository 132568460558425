import { MESSAGES_ACTIONS } from '../../constants/actions';
import { Message } from '../../types/state/reducers/messagesReducersTypes';

export const setMessages = (messages: Message[]) => ({
  type: MESSAGES_ACTIONS.SET_MESSAGES,
  messages: messages
});

export const addMessage = (message: Message) => ({
  type: MESSAGES_ACTIONS.ADD_MESSAGE,
  messages: [message]
});

export const clearMessages = () => ({
  type: MESSAGES_ACTIONS.CLEAR_MESSAGES
});
