import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ENV } from '../../constants/environments';
import {
  pageview,
  purchased,
  subscribed,
  initCheckout,
} from '../../shared/analytics/Facebook';

import { ApplicationState } from '../../types/state/storeTypes';
import { EventTrackingStateProps, EventTrackingProps } from '../../types/components/shared/EventTracking';
import { CollectionsShowResponse } from '../../types/api/CollectionsShowTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { User } from '../../types/api/UsersTypes';

const EventTracking: React.FC<EventTrackingProps> = (props: EventTrackingProps) => {
  const {
    currentProduct,
    visitingHomepage,
    subscriptionDetails,
    creditPackDetails,
    checkoutDetails,
    user,
  } = props;

  const router = useRouter();
  const [dataLayer, setDataLayer] = useState();

  if (ENV.appEnv !== 'production') return <></>;

  useEffect(() => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    const dataLayer = (window as any).dataLayer;
    setDataLayer(dataLayer);
  }, []);

  useEffect(() => {
    pageview();
  }, [router.asPath]);

  const track = (
    eventName: string,
    eventData?: CollectionsShowResponse | SubscriptionDetail | CreditPackResponse | { customAmount: number, type: string }
  ) => {
    const fp = (window as any)?.flowpoint;
    if (!fp) return;
    fp.track(eventName, eventData || {});
  };

  const handleViewProduct = () => {
    if (!currentProduct) return;
    const eventName = 'View product';
    track(eventName, currentProduct);
  }

  const handleHomepageVisit = () => {
    if (!visitingHomepage) return;
    const eventName = 'Homepage visit';
    track(eventName);
  }

  const handleInitiateCheckout = () => {
    if (!checkoutDetails) return;
    const { planVariant } = checkoutDetails;
    const contents = [{
      id: planVariant.id,
      price: planVariant.price,
      quantity: 1,
      name: planVariant.name,
      url: router.asPath,
      sku: planVariant.sku,
    }];
    initCheckout({ contents });
  };

  const handleSubscription = () => {
    if (!subscriptionDetails) return;
    const { id, cost, plan_variant } = subscriptionDetails;
    const { name } = plan_variant;
    subscribed(id, subscriptionDetails.plan_variant.price);
    const contents = [{
      id: id,
      price: cost,
      quantity: 1,
      name,
      url: router.asPath,
    }];
    const content_type = 'subscription';
    purchased({
      contents,
      content_type,
      value: cost,
      currency: 'USD',
      content_ids: [id.toString()]
    });
    const eventName = 'Subscription';
    track(eventName, subscriptionDetails);
  }

  const handleCreditPackPurchase = () => {
    if (!creditPackDetails) return;
    const { customAmount, creditPack } = creditPackDetails;
    const { id, cost_per_credit, price, recurring_credits } = creditPack;
    const _price: number = customAmount ? parseFloat(cost_per_credit) * customAmount : parseFloat(price);

    // pixel/meta
    const contents = [{
      id: id,
      price: _price,
      quantity: customAmount || recurring_credits,
      name: 'Credit pack',
      url: router.asPath,
    }];
    const content_type = 'credit_pack';
    purchased({
      contents,
      content_type,
      value: _price,
      currency: 'USD',
      content_ids: [customAmount ? 'custom' : id.toString()]
    });

    // flowpoint
    const eventName = 'Credit pack purchase';
    const details = customAmount ? { customAmount, type: 'Custom amount purchase' } : creditPack;
    track(eventName, details);

    // dataLayer
    if (!dataLayer) return;
    const items = [{
      item_id: customAmount ? 'custom' : id,
      item_name: customAmount ? `Custom credit amount - ${customAmount} credits` : `Credit pack - ${recurring_credits} credits`,
      price: _price,
    }];
    const userObject = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email
    };
    (dataLayer as Array<any>).push({
      event: 'purchase',
      transaction_id: '',
      currency: 'USD',
      value: _price,
      items,
      user: userObject
    });
  }

  useEffect(handleViewProduct, [currentProduct]);
  useEffect(handleHomepageVisit, [visitingHomepage]);
  useEffect(handleSubscription, [subscriptionDetails]);
  useEffect(handleCreditPackPurchase, [creditPackDetails]);
  useEffect(handleInitiateCheckout, [checkoutDetails]);

  return <></>
};

const mapStateToProps = (state: ApplicationState): EventTrackingStateProps => ({
  currentProduct: state.eventTracking.currentProduct,
  visitingHomepage: state.eventTracking.visitingHomepage,
  subscriptionDetails: state.eventTracking.subscriptionDetails,
  creditPackDetails: state.eventTracking.creditPackDetails,
  checkoutDetails: state.eventTracking.checkoutDetails,
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(EventTracking);
