import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { addMessage } from '../../state/actions/messagesActions';
import { setUserVerificationEmailSent } from '../../state/actions/userVerificationActions';
import { Loader } from '../shared/Loader';

import { ApplicationState } from '../../types/state/storeTypes';

const EmailNotVerifiedMessage: React.FC<any> = (props: any) => {
  const warningMessage =
    'Your email address has not been confirmed. Email confirmation is required to download free assets and practice footage.';

  const { user, userVerification } = props;

  if (userVerification?.emailSent || sessionStorage.getItem(`${user.email}_verified`) === 'true') {
    return <>{`${warningMessage} A confirmation email has been sent to ${user.email}`}</>;
  }

  const dispatch = useDispatch();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  const sendVerificationEmail = async () => {
    if (sendingEmail) return;
    setSendingEmail(true);
    dispatch({
      type: '',
      api: {
        method: 'POST',
        url: `/users/confirmation?email=${encodeURIComponent(user.email)}`,
        onError: (res: any) => {
          dispatch(
            addMessage({
              type: 'error',
              body: res.message
            })
          );
          dispatch(setUserVerificationEmailSent(false));
          setSendingEmail(false);
        },
        onSuccess: (res: any) => {
          dispatch(
            addMessage({
              type: 'success',
              body: res.data.message
            })
          );
          dispatch(setUserVerificationEmailSent(true));
          sessionStorage.setItem(`${user.email}_verified`, 'true');
          setSendingEmail(false);
        }
      }
    });
  };

  return (
    <>
      {`${warningMessage} Confirm your email address by `}
      <span
        className="cursor-pointer text-a-blue hover:underline"
        onClick={() => sendVerificationEmail()}>
        clicking this link.
      </span>
      {sendingEmail && <Loader />}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.user,
  userVerification: state.userVerification
});

export default connect(mapStateToProps)(EmailNotVerifiedMessage);
