import React from 'react';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';
import store from '../state/store';
import { initializeLocalForage } from './../shared/libs/Storage';
import NextNProgress from 'nextjs-progressbar';
import { SessionProvider } from 'next-auth/react';
import Notifications from '../components/shared/Notifications';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ENV } from '../constants/environments';
import '../styles/globals.scss';
import { getSession } from 'next-auth/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-modal/styles.css';
import 'react-tooltip/dist/react-tooltip.css';
import { AUTH_ACTIONS } from '../constants/actions';
import { connect } from 'react-redux';
import EmailNotVerifiedNotification from '../components/shared/EmailNotVerifiedNotification';
import EventTracking from '../components/shared/EventTracking';
import RateLimitingListenerPlugin from '../components/rate-limiting/RateLimitingListenerPlugin';
import { SessionState } from '../types/state/reducers/authReducersTypes';
import { addNotification } from '../state/actions/notificationsActions';
import VWOIntegration from '../components/analytics/VWOIntegration';
import PasswordProtection from '../components/staging/PasswordProtection';

const { AUTH_USER_INFO_OK, AUTH_SET_USER_NOT_LOGGED_IN } = AUTH_ACTIONS;

initializeLocalForage();

class ActionVfx extends React.Component<AppProps, { shouldDisplayPasswordProtection: boolean }> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      shouldDisplayPasswordProtection: false,
    };
  }

  async componentDidMount() {
    this.setState({
      shouldDisplayPasswordProtection: ENV.shouldDisplayPasswordProtection === 'true'&& sessionStorage.getItem('accessValidated') !== 'true',
    });
    try {
      const sessionData: SessionState = await getSession();
      const userAuthStatusParams = sessionData?.user
        ? { type: AUTH_USER_INFO_OK, payload: { authenticated: true, ...sessionData } }
        : { type: AUTH_SET_USER_NOT_LOGGED_IN };
      store.dispatch(userAuthStatusParams);
      if (!!sessionData?.user && !sessionData?.user.confirmed_at)
        store.dispatch(
          addNotification({
            body: <EmailNotVerifiedNotification />
          })
        );
    } catch (error) {
      store.dispatch({ type: AUTH_SET_USER_NOT_LOGGED_IN });
    }
  }

  renderPageContent(): React.ReactElement {
    if (this.state.shouldDisplayPasswordProtection) return;
    const { Component, pageProps } = this.props;
    return (
      <>
        <NextNProgress
          color="#00A1E0"
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={false}
        />
        <GoogleReCaptchaProvider
          reCaptchaKey={ENV.googleRecaptchaSiteKey}
          scriptProps={{
            async: true,
          }}
        >
          <VWOIntegration />
          <Component {...pageProps} />
          <Notifications />
          <EventTracking />
          <RateLimitingListenerPlugin />
        </GoogleReCaptchaProvider>
      </>
    )
  }

  onPasswordValidated(): void {
    this.setState({ shouldDisplayPasswordProtection: false });
  }

  renderPasswordProtection(): React.ReactElement {
    if (!this.state.shouldDisplayPasswordProtection) return;
    return (
      <PasswordProtection
        onPasswordValidated={() => this.onPasswordValidated()}
      />
    );
  }

  render() {
    const { pageProps } = this.props;

    return (
      <SessionProvider session={pageProps.session}>
        <Provider store={store}>
          {this.renderPageContent()}
          {this.renderPasswordProtection()}
        </Provider>
      </SessionProvider>
    );
  }
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

const ActionVFXConnected = connect()(ActionVfx);

export default wrapper.withRedux(ActionVFXConnected);
