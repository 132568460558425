import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

export const Loader: React.FC = () => {
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-[99] flex items-center justify-center" cy-test-id="loader">
      <div className="bg-black px-8 rounded-[10px]">
        <ThreeDots
          height="64"
          width="64"
          radius="6"
          color="#00A1E0"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      </div>
    </div>
  );
};
