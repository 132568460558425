import { AnyAction } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { newState } from '../../helpers/state/reducers/new-state';
import { AUTH_ACTIONS, AUTH_MW_ACTIONS, CREDIT_ACTIONS } from './../../constants/actions';
import { AuthState } from '../../types/state/reducers/authReducersTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';

const { AUTH_MW_STATUS } = AUTH_MW_ACTIONS;

const {
  AUTH_SIGN_IN,
  AUTH_SIGN_IN_OK,
  AUTH_SHOW_LOGIN,
  AUTH_HIDE_LOGIN,
  AUTH_SHOW_SIGN_UP,
  AUTH_HIDE_SIGN_UP,
  AUTH_SIGN_IN_ERR,
  AUTH_SIGN_OUT_OK,
  AUTH_SIGN_OUT_ERR,
  AUTH_REGISTER,
  AUTH_REGISTER_OK,
  AUTH_REGISTER_ERR,
  AUTH_USER_INFO_OK,
  AUTH_USER_INFO_ERR,
  AUTH_RESET_ERR,
  AUTH_SET_USER_NOT_LOGGED_IN,
  AUTH_ON_SUBSCRIBED,
  AUTH_SET_FREE_SUBSCRIBER_STATUS,
  AUTH_ON_CANCELLED_SUBSCRIPTION,
  AUTH_ON_REACTIVATED_SUBSCRIPTION,
} = AUTH_ACTIONS;
const {
  INCREMENT_AVAILABLE_CREDIS,
  DECREMENT_AVAILABLE_CREDITS,
  SET_AVAILABLE_CREDITS,
} = CREDIT_ACTIONS;

const initAuthState: AuthState = {
  // IMPORTANT: authenticated should be UNDEFINED b/c the app doesn't know
  // if user's logged in or not initially. After fetching session we set this explicitly
  // to TRUE or FALSE
  authenticated: undefined,
  showLogin: false,
  showSignUp: false,
  loading: false,
  error: null,
  user: {}
} as const;

const onStatus = (state: AuthState, authenticated: boolean): AuthState => {
  return newState<AuthState>(state, { authenticated });
};

const onStartAuth = (state: AuthState): AuthState => {
  const fresh = { loading: true, error: null };
  return newState<AuthState>(state, fresh);
};

const onSignIn = (state: AuthState, response: AxiosResponse): AuthState => {
  const { id, first_name, last_name, email } = response.data?.data || {};
  const user = { id, first_name, last_name, email };
  const fresh = { authenticated: true, loading: false, error: null, user };
  return newState<AuthState>(state, fresh);
};

const onShowLogin = (state: AuthState, err?: Error): AuthState => {
  return newState<AuthState>(state, { showLogin: true, showSignUp: false });
};

const onHideLogin = (state: AuthState, err?: Error): AuthState => {
  return newState<AuthState>(state, { showLogin: false, showSignUp: false });
};

const onShowSignUp = (state: AuthState, err?: Error): AuthState => {
  return newState<AuthState>(state, { showSignUp: true, showLogin: false });
};

const onHideSignUp = (state: AuthState, err?: Error): AuthState => {
  return newState<AuthState>(state, { showSignUp: false, showLogin: false });
};

const onSignOut = (state: AuthState, err?: Error): AuthState => {
  const user = {};
  const fresh = { authenticated: false, loading: false, error: err, user };
  return newState<AuthState>(state, fresh);
};

const onuser = (state: AuthState, payload): AuthState => {
  const { authenticated, subscription, user, notifications } = payload || {};
  const fresh = { authenticated, subscription, user, user_notifications: notifications };
  return newState<AuthState>(state, fresh);
};

const onError = (state: AuthState, ex: AxiosError): AuthState => {
  const errData: any = ex.response?.data;
  const [msg]: string[] = errData?.errors?.full_messages || [errData?.error] || [];
  const error: Error = msg ? new Error(msg) : (ex as Error);

  return newState<AuthState>(state, { loading: false, error, authenticated: false, user: {} });
};

const onNotLoggedInUser = (state: AuthState): AuthState => {
  return newState<AuthState>(state, { authenticated: false });
};

const onIncrementCredits = (state: AuthState, amount: number) => {
  const { subscription } = state;
  const updatedSubscription = { ...subscription, current_credit_balance: (subscription as any).current_credit_balance + amount }
  return newState<AuthState>(state, { subscription: updatedSubscription });
}

const onDecrementCredits = (state: AuthState, amount: number): AuthState => {
  const { subscription } = state;
  const updatedSubscription = { ...subscription, current_credit_balance: (subscription as any).current_credit_balance - amount }
  return newState<AuthState>(state, { subscription: updatedSubscription });
};

const onSetCredits = (state: AuthState, amount: number): AuthState => {
  const { subscription } = state;
  const updatedSubscription = { ...subscription, current_credit_balance: amount }
  return newState<AuthState>(state, { subscription: updatedSubscription });
};

const onSubscribed = (state: AuthState, subscription: SubscriptionDetail): AuthState => {
  return newState<AuthState>(state, { subscription });
}

const onFreeSubscriberStatusChange = (state: AuthState, freeSubscriberStatus: boolean): AuthState => {
  const { user } = state;
  const updatedUser = { ...user, free_subscriber: freeSubscriberStatus };
  return newState<AuthState>(state, { user: updatedUser });
}

const onSubscriptionCancelled = (state: AuthState, cancel_at: string): AuthState => {
  const { subscription } = state;
  const updatedSubscription = { ...subscription, cancel_at };
  return newState<AuthState>(state, { subscription: updatedSubscription });
}

const onSubscriptionReactivated = (state: AuthState): AuthState => {
  const { subscription } = state;
  const updatedSubscription = { ...subscription, cancel_at: null };
  return newState<AuthState>(state, { subscription: updatedSubscription });
}

export const authReducer = (state: AuthState = initAuthState, action: AnyAction): AuthState => {
  const handlers = {
    [AUTH_SHOW_LOGIN]: (): AuthState => onShowLogin(state),
    [AUTH_HIDE_LOGIN]: (): AuthState => onHideLogin(state),
    [AUTH_SHOW_SIGN_UP]: (): AuthState => onShowSignUp(state),
    [AUTH_HIDE_SIGN_UP]: (): AuthState => onHideSignUp(state),
    [AUTH_MW_STATUS]: (): AuthState => onStatus(state, action.authenticated),
    [AUTH_SIGN_IN]: (): AuthState => onStartAuth(state),
    [AUTH_REGISTER]: (): AuthState => onStartAuth(state),
    [AUTH_SIGN_IN_OK]: (): AuthState => onSignIn(state, action.response),
    [AUTH_SIGN_IN_ERR]: (): AuthState => onError(state, action.error),
    [AUTH_SIGN_OUT_OK]: (): AuthState => onSignOut(state),
    [AUTH_SIGN_OUT_ERR]: (): AuthState => onSignOut(state, action.error),
    [AUTH_REGISTER_OK]: (): AuthState => state,
    [AUTH_REGISTER_ERR]: (): AuthState => onError(state, action.error),
    [AUTH_USER_INFO_OK]: (): AuthState => onuser(state, action.payload),
    [AUTH_USER_INFO_ERR]: (): AuthState => onError(state, action.error),
    [AUTH_RESET_ERR]: (): AuthState => onError(state, action.error),
    [AUTH_SET_USER_NOT_LOGGED_IN]: (): AuthState => onNotLoggedInUser(state),
    [INCREMENT_AVAILABLE_CREDIS]: (): AuthState => onIncrementCredits(state, action.amount),
    [DECREMENT_AVAILABLE_CREDITS]: (): AuthState => onDecrementCredits(state, action.amount),
    [SET_AVAILABLE_CREDITS]: (): AuthState => onSetCredits(state, action.amount),
    [AUTH_ON_SUBSCRIBED]: (): AuthState => onSubscribed(state, action.subscription),
    [AUTH_SET_FREE_SUBSCRIBER_STATUS]: (): AuthState => onFreeSubscriberStatusChange(state, action.freeSubscriberStatus),
    [AUTH_ON_CANCELLED_SUBSCRIPTION]: (): AuthState => onSubscriptionCancelled(state, action.cancel_at),
    [AUTH_ON_REACTIVATED_SUBSCRIPTION]: (): AuthState => onSubscriptionReactivated(state),
  };

  const handler = handlers[action.type];
  return handler ? handler() : state;
};
