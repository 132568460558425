import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { ApiOptions } from '../../types/state/middleware/apiTypes';
import { API_ACTIONS } from './../../constants/actions';
import { default as axios, AxiosResponse, AxiosError } from 'axios';

const { API_SUCCESS, API_ERROR } = API_ACTIONS;

const defaultOptions: ApiOptions = {
  responseType: 'json',
  withCredentials: true
};

const successCreator = (response: AxiosResponse, actionType?: string): AnyAction => {
  return { type: actionType || API_SUCCESS, response };
};

const errorCreator = (error: AxiosError, actionType?: string): AnyAction => {
  return { type: actionType || API_ERROR, error };
};

export const initApiClient = (baseOptions: ApiOptions = {}): Middleware => {
  const headers: any = Object.assign({}, baseOptions.headers);

  return (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    if (!action.api) return next(action);

    const options = { ...defaultOptions, ...baseOptions, ...action.api };
    const { onSuccess, onError, successAction, errorAction } = options;
    const { dispatch } = store;

    options.headers = Object.assign({}, headers, options.headers);

    const success = (res: AxiosResponse): void => {
      if (onSuccess) onSuccess(res);
      dispatch(successCreator(res, successAction));
    };

    const errored = (ex: AxiosError): void => {
      if (onError) onError(ex);

      dispatch(errorCreator(ex, errorAction));
    };

    axios.request(options).then(success).catch(errored);

    return next(action);
  };
};
