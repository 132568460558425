import { SubscriptionUpgradeState } from '../../types/state/reducers/subscriptionUpgradeReducersTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { SUBSCRIPTION_UPGRADE_ACTIONS } from '../../constants/actions';
const {
  SHOW_UPGRADE_SUBSCRIPTION_MODAL,
  HIDE_UPGRADE_SUBSCRIPTION_MODAL,
} = SUBSCRIPTION_UPGRADE_ACTIONS;

const initialState: SubscriptionUpgradeState = {
  showUpgradeSubscriptionModal: false,
};

export const subscriptionUpgradeReducer = (
  state: SubscriptionUpgradeState = initialState,
  action: {
    type: string,
    selectedPlanVariantId?: number,
    licenseId?: LicenseId,
  },
): SubscriptionUpgradeState => {
  const { type, selectedPlanVariantId, licenseId } = action;
  switch(type) {
    case SHOW_UPGRADE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        showUpgradeSubscriptionModal: true,
        selectedPlanVariantId: selectedPlanVariantId || null,
        licenseId: licenseId || null,
      };
    case HIDE_UPGRADE_SUBSCRIPTION_MODAL:;
      return initialState;
    default:
      return state;
  }
}
