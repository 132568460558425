import { NOTIFICATIONS_ACTIONS } from '../../constants/actions';
import { Notification, OneTimeNotificationType } from '../../types/state/reducers/notificationsReducersTypes';

export const setNotifications = (notifications: Notification[]) => ({
  type: NOTIFICATIONS_ACTIONS.SET_NOTIFICATIONS,
  notifications: notifications
});

export const addNotification = (notification: Notification) => {
  return {
    type: NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION,
    notification: notification
  }
};

export const clearNotifications = () => ({
  type: NOTIFICATIONS_ACTIONS.CLEAR_NOTIFICATIONS,
});

export const removeNotification = (notification: Notification) => {
  return {
    type: NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION,
    notification: notification,
  }
}

export const setOneTimeNotififcationShown = (notificationType: OneTimeNotificationType) => ({
  type: NOTIFICATIONS_ACTIONS.ONE_TIME_NOTIFICATIONS.SET_SHOWN,
  oneTimeNotification: notificationType,
});
