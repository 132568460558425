import debounce from 'lodash.debounce';
import { Axios, AxiosResponse } from 'axios';
import Cookie from 'js-cookie';
import { Middleware, Dispatch, AnyAction } from 'redux';
import { ENV } from './../../constants/environments';
import { AUTH_ACTIONS, AUTH_MW_ACTIONS, SHARED } from './../../constants/actions';
import { AuthHeaders, Credentials, Registration } from '../../types/state/middleware/authTypes';
import { AUTH_HEADER_KEY } from '../../constants/api';

const DEBOUNCE_MS = 100;

const {
  AUTH_MW_SIGN_IN,
  AUTH_MW_SIGN_OUT,
  AUTH_MW_REGISTER,
  AUTH_MW_STATUS,
  AUTH_MW_USER_INFO,
  AUTH_MW_TEAM_INFO,
  AUTH_MW_SUBSCRIPTION_GET_DETAILS,
  AUTH_MW_TEAM_INVITE_USER,
  AUTH_MW_RESET_PASSWORD
} = AUTH_MW_ACTIONS;

const {
  AUTH_STATUS,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_REGISTER,
  AUTH_PASSWORDLESS_REGISTER,
  AUTH_UPDATE_PROFILE,
  AUTH_SUBSCRIPTION_DETAILS,
  AUTH_USER_INFO,
  AUTH_TEAM_INFO,
  AUTH_TEAM_INVITE_USER,
  AUTH_SIGN_IN_OK,
  AUTH_SIGN_IN_ERR,
  AUTH_SIGN_OUT_OK,
  AUTH_SIGN_OUT_ERR,
  AUTH_REGISTER_OK,
  AUTH_REGISTER_ERR,
  AUTH_USER_INFO_OK,
  AUTH_USER_INFO_ERR,
  AUTH_TEAM_INFO_OK,
  AUTH_TEAM_INFO_ERR,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_ERR,
  AUTH_DELETE_USER,
} = AUTH_ACTIONS;

type OnSuccessType = (res: AxiosResponse) => void;

const loadInitialHeaders = (): AuthHeaders => {
  const fromStore: string = Cookie.get(AUTH_HEADER_KEY);

  if (!fromStore) return {};

  try {
    return JSON.parse(fromStore);
  } catch (ex) {
    Cookie.remove(AUTH_HEADER_KEY);
    return {};
  }
};

const headers: AuthHeaders = loadInitialHeaders();

const clearHeaders = (): void => {
  Object.keys(headers).forEach((key) => {
    delete headers[key];
  });

  Cookie.remove(AUTH_HEADER_KEY);
};

// const headerUpdater = (onSuccess?: OnSuccessType): OnSuccessType => (res) => {
//   if (onSuccess) onSuccess(res);
//   const { authorization } = res.headers || {};
//   if (authorization) {
//     headers.authorization = authorization;
//     storeHeaders();
//   }
// };

const headersValid = (): boolean => {
  return true;
};

const statusCreator = (): AnyAction[] => {
  const authenticated = headersValid();
  return [{ type: AUTH_MW_STATUS, authenticated }, userCreator()];
};

const signInCreator = (action: AnyAction): AnyAction => {
  const { email, password }: Credentials = action.sensitive || {};

  return {
    type: AUTH_MW_SIGN_IN,
    api: {
      url: '/users/sign_in',
      data: { email, password },
      method: 'POST',
      successAction: AUTH_SIGN_IN_OK,
      errorAction: AUTH_SIGN_IN_ERR,
      onSuccess: action.onSuccess
    }
  };
};

const passwordResetCreator = (action: AnyAction): AnyAction => {
  const {
    headers,
    sensitive: { password, passwordConfirm }
  } = action;

  return {
    type: AUTH_MW_RESET_PASSWORD,
    api: {
      method: 'PUT',
      url: `/users/password`,
      headers: headers,
      data: {
        password,
        password_confirmation: passwordConfirm
      },
      // successAction: AUTH_SIGN_IN_OK,
      // errorAction: AUTH_RESET_ERR,
      onSuccess: action.onSuccess,
      onError: action.onError,
    }
  };
};

const signOutCreator = (action: AnyAction): AnyAction => {
  clearHeaders();

  return {
    type: AUTH_MW_SIGN_OUT,
    api: {
      url: '/auth/sign_out',
      method: 'DELETE',
      successAction: AUTH_SIGN_OUT_OK,
      errorAction: AUTH_SIGN_OUT_ERR,
      onSuccess: action.onSuccess
    }
  };
};

const userCreator = (action?: AnyAction): AnyAction => {
  if (!headersValid()) return { type: SHARED.NOOP };

  return {
    type: AUTH_MW_USER_INFO,
    api: {
      url: '/users/me',
      method: 'GET',
      successAction: AUTH_USER_INFO_OK,
      errorAction: AUTH_USER_INFO_ERR,
      onSuccess: action?.onSuccess
    }
  };
};

const teamCreator = (action?: AnyAction): AnyAction => {
  if (!headersValid()) return { type: SHARED.NOOP };

  return {
    type: AUTH_MW_TEAM_INFO,
    api: {
      url: '/teams',
      method: 'GET',
      headers: action?.headers,
      successAction: AUTH_TEAM_INFO_OK,
      errorAction: AUTH_TEAM_INFO_ERR,
      onSuccess: action?.onSuccess
    }
  };
};

const inviteCreator = (action?: AnyAction): AnyAction => {
  const { email, role_id }: any = action.sensitive || {};

  if (!headersValid()) return { type: SHARED.NOOP };

  return {
    type: AUTH_MW_TEAM_INVITE_USER,
    api: {
      url: '/teams/invites',
      data: { email, role_id },
      method: 'POST',
      headers: action?.headers,
      onSuccess: action.onSuccess,
      onError: action.onError
    }
  };
};

const profileCreator = (action?: AnyAction): AnyAction => {
  const { email, password, password_confirmation, username, first_name, last_name, phone, company, tax_vat_number, free_subscriber, email_optin }: Registration =
    action.sensitive || {};

  if (!headersValid()) return { type: SHARED.NOOP };

  return {
    type: AUTH_UPDATE_PROFILE,
    api: {
      url: '/users/account',
      data: { email, password, password_confirmation, username, first_name, phone, last_name, company, tax_vat_number, free_subscriber, email_optin },
      method: 'PATCH',
      successAction: AUTH_REGISTER_OK,
      errorAction: AUTH_REGISTER_ERR,
      headers: action?.headers,
      onSuccess: action.onSuccess,
      onError: action.onError
    }
  };
};

const subscriptionDetailsCreator = (action?: AnyAction): AnyAction => {
  if (!headersValid()) return { type: SHARED.NOOP };

  return {
    type: AUTH_MW_SUBSCRIPTION_GET_DETAILS,
    api: {
      url: '/subscriptions/show/details',
      method: 'GET',
      onSuccess: action?.onSuccess,
      onError: action?.onError
    }
  };
};

const registerCreator = (action: AnyAction): AnyAction => {
  const {
    email,
    password,
    first_name,
    last_name,
    confirm_success_url = ENV.authReturnTo,
    company,
    free_subscriber,
    email_optin
  }: Registration = action.sensitive || {};

  return {
    type: AUTH_MW_REGISTER,
    api: {
      url: '/users',
      data: { email, password, first_name, last_name, company, free_subscriber, email_optin, confirm_success_url },
      method: 'POST',
      successAction: AUTH_REGISTER_OK,
      errorAction: AUTH_REGISTER_ERR,
      onSuccess: action.onSuccess,
      onError: action.onError,
    }
  };
};

const passwordlessRegisterCreator = (action: AnyAction): AnyAction => {
  const {
    email,
    first_name,
    last_name,
    password,
    confirm_success_url = ENV.authReturnTo
  }: Registration = action.sensitive || {};

  return {
    type: AUTH_MW_REGISTER,
    api: {
      url: '/users/passwordless_create',
      data: { email, first_name, last_name, password, confirm_success_url },
      method: 'POST',
      successAction: AUTH_REGISTER_OK,
      errorAction: AUTH_REGISTER_ERR,
      onSuccess: action.onSuccess,
      onError: action.onError
    }
  };
};

const applyteam = (response: AxiosResponse): void => {
  const { current_user, members, roles, team } = response.data?.data || {};
};

const injectHeaderHandler = (action: AnyAction): void => {
  if (action.api && !action.api.baseUrl) {
    action.api.headers = Object.assign({}, headers, action.api.headers);
    // action.api.onSuccess = headerUpdater(action.api.onSuccess);
  }
};

const deleteUserCreator = (action: AnyAction): AnyAction => {
  return {
    type: AUTH_MW_REGISTER,
    api: {
      url: '/users/account/anonymize',
      method: 'PATCH',
      headers: action.headers,
      successAction: AUTH_SIGN_OUT_OK,
      onSuccess: action.onSuccess,
      onError: action.onError
    }
  };
}

export const authMiddleware: Middleware =
  () =>
  (next: Dispatch) =>
  (action: AnyAction): void => {
    const creators = {
      [AUTH_STATUS]: (): AnyAction[] => statusCreator(),
      [AUTH_SIGN_IN]: (): AnyAction => signInCreator(action),
      [AUTH_SIGN_OUT]: (): AnyAction => signOutCreator(action),
      [AUTH_REGISTER]: (): AnyAction => registerCreator(action),
      [AUTH_PASSWORDLESS_REGISTER]: (): AnyAction => passwordlessRegisterCreator(action),
      [AUTH_UPDATE_PROFILE]: (): AnyAction => profileCreator(action),
      [AUTH_USER_INFO]: (): AnyAction => userCreator(action),
      [AUTH_TEAM_INFO]: (): AnyAction => teamCreator(action),
      [AUTH_TEAM_INVITE_USER]: (): AnyAction => inviteCreator(action),
      [AUTH_RESET_PASSWORD]: (): AnyAction => passwordResetCreator(action),
      [AUTH_SUBSCRIPTION_DETAILS]: (): AnyAction => subscriptionDetailsCreator(action),
      [AUTH_DELETE_USER]: (): AnyAction => deleteUserCreator(action),
    };

    const sideEffects = {
      // [AUTH_SIGN_IN_OK]: (): void => applyuser(action.response),
      // [AUTH_USER_INFO_OK]: (): void => applyuser(action.response),
      [AUTH_TEAM_INFO_OK]: (): void => applyteam(action.response)
    };

    sideEffects[action.type] && sideEffects[action.type]();

    const createdAction = creators[action.type] && creators[action.type]();

    injectHeaderHandler(action);
    next(action);

    if (!createdAction) return;

    if (Array.isArray(createdAction)) {
      createdAction.forEach(injectHeaderHandler);
      createdAction.forEach(next);
    } else {
      injectHeaderHandler(createdAction);
      next(createdAction);
    }
  };
