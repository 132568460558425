import { ENV } from './environments';
import { ApiOptions } from '../types/state/middleware/apiTypes';

export const API_CONFIG_STORE: ApiOptions = {
  baseURL: ENV.api.baseURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
} as const;

export const AUTH_HEADER_KEY: string = 'avfx_headers';

export const ERROR_CODES = {
  RATE_LIMIT_REACHED: 503,
  DOWNLOAD_LIMIT_REACHED: 507,
};

export const LIBRARY_IDS = {
  LIBRARY_ID_2D: 1,
  LIBRARY_ID_PFL: 2,
  LIBRARY_ID_3D: 3,
};

export const CACHE_KEYS = {
  COLLECTIONS_CACHE_KEY_2D: '202309release',
  COLLECTIONS_CACHE_KEY_3D: '3d_library_collections',
  CATEGORIES_CACHE_KEY_2D: 'avfx_categories_all',
  CATEGORIES_CACHE_KEY_3D: 'avfx_categories_3d',
};
