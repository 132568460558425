import { EventTrackingReducerState } from '../../types/state/reducers/eventTrackingReducersTypes';
import { CollectionsShowResponse } from '../../types/api/CollectionsShowTypes';
import { SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { CreditPackResponse } from '../../types/api/CreditsTypes';

import { EVENT_TRACKING_ACTIONS } from '../../constants/actions';
const {
  CURRENT_PRODUCT,
  HOMEPAGE,
  CONVERSION,
  PURCHASE,
  CHECKOUT,
} = EVENT_TRACKING_ACTIONS;
const {
  SET_PRODUCT,
  CLEAR_PRODUCT,
} = CURRENT_PRODUCT;
const {
  SET_VISITING,
  CLEAR_VISITING,
} = HOMEPAGE;
const {
  SET_SUBSCRIBED,
  CLEAR_SUBSCRIBED,
} = CONVERSION;
const {
  SET_CREDIT_PACK,
  CLEAR_CREDIT_PACK,
} = PURCHASE;
const {
  SET_INITIATE_CHECKOUT,
  CLEAR_INITIATE_CHECKOUT,
} = CHECKOUT;

const initialState: EventTrackingReducerState = {};

export const eventTrackingReducer = (
  state: EventTrackingReducerState = initialState,
  action: {
    type: string,
    product?: CollectionsShowResponse,
    plan?: SubscriptionDetail,
    creditPack?: CreditPackResponse,
    customAmount?: number,
    checkoutDetails?: { planVariant: PlanVariantResponse },
  }
): EventTrackingReducerState => {
  const {
    type,
    product,
    plan,
    creditPack,
    customAmount,
    checkoutDetails,
  } = action;
  switch (type) {
    case SET_PRODUCT:
      return { ...state, currentProduct: product };
    case CLEAR_PRODUCT:
      return { ...state, currentProduct: undefined };
    case SET_VISITING:
      return { ...state, visitingHomepage: true };
    case CLEAR_VISITING:
      return { ...state, visitingHomepage: false };
    case SET_SUBSCRIBED:
      return { ...state, subscriptionDetails: plan };
    case CLEAR_SUBSCRIBED:
      return { ...state, subscriptionDetails: undefined };
    case SET_CREDIT_PACK:
      return { ...state, creditPackDetails: { creditPack, customAmount } };
    case CLEAR_CREDIT_PACK:
      return { ...state, creditPackDetails: undefined };
    case SET_INITIATE_CHECKOUT:
      return { ...state, checkoutDetails };
    case CLEAR_INITIATE_CHECKOUT:
      return { ...state, checkoutDetails: undefined };
    default:
      return state;
  }
}
