import { LICENSE_FLOW_ACTIONS } from '../../constants/actions';
const {
  SHOW_UPDATE_LICENSE_MODAL,
  HIDE_UPDATE_LICENSE_MODAL,
} = LICENSE_FLOW_ACTIONS;

import { LicenseFlowState } from '../../types/state/reducers/licenseFlowReducersTypes';

const initialState: LicenseFlowState = {
  showUpdateLicenseModal: false,
};

export const licenseFlowReducer = (
  state: LicenseFlowState = initialState,
  action: { type: string },
): LicenseFlowState => {
  switch (action.type) {
    case SHOW_UPDATE_LICENSE_MODAL: return { ...state, showUpdateLicenseModal: true };
    case HIDE_UPDATE_LICENSE_MODAL: return { ...state, showUpdateLicenseModal: false };
    default: return state;
  }
}
