import { PasswordProtectionProps } from '../../types/components/staging/PasswordProtection';

import { useState, FormEvent } from 'react';

import axios from 'axios';
import sha256Hash from '../../helpers/encryptHash';

const PasswordProtection: React.FC<PasswordProtectionProps> = ({
  onPasswordValidated,
}) => {
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const submitDisabled = (): boolean =>
    loading || !password?.length;

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (submitDisabled()) return;
    setError('');
    setLoading(true);
    sha256Hash([password], validatePassword, handleError);
  };

  const validatePassword = async (hashResult: Array<string>) => {
    if (!hashResult?.length) {
      handleError('An error has occured');
      return;
    }
    const hashedPassword = hashResult[0];
    try {
      const result = await axios.post('/api/authenticate', { password: hashedPassword });
      if (result.status === 200) handleSuccess();
      else handleError('An error has occured');
    } catch (e) {
      handleError(e?.response?.data?.error || 'An error has occured');
    }
  }

  const handleSuccess = () => {
    setLoading(false);
    sessionStorage.setItem('accessValidated', 'true');
    onPasswordValidated();
  }

  const handleError = (error: string) => {
    setLoading(false);
    setError(error);
  }

  return (
    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#fff] flex items-center justify-center">
      <form
        className="w-[400px] max-w-full flex flex-col gap-4"
        onSubmit={event => onSubmit(event)}
      >
        <input
          type="password"
          placeholder="Enter password"
          className="w-full p-2 border-[1px] border-[#9e9e9e] rounded-[5px] disabled:opacity-30"
          onChange={event => setPassword(event.target.value)}
          disabled={loading}
        />
        <button
          type="submit"
          className="text-white bg-a-blue w-full p-2 rounded-[5px] disabled:opacity-30"
          disabled={submitDisabled()}
        >
          Enter website
        </button>
        <div className="h-10">
          {
            error &&
            <div className="bg-red-800 rounded-[5px] p-2 text-center text-white">
              {error}
            </div>
          }
        </div>
      </form>
    </div>
  );
};

export default PasswordProtection;
