import EmailNotVerifiedMessage from './EmailNotVerifiedMessage';

const EmailNotVerifiedNotification: React.FC<any> = () => {
  return (
    <div className="w-full inter -my-2">
      <span className="uppercase font-bold mr-1">warning:</span>
      <span className="text-[14px]">
        <EmailNotVerifiedMessage />
      </span>
    </div>
  )
}

export default EmailNotVerifiedNotification;
