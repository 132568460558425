import { CollectionsShowResponse } from '../../../types/api/CollectionsShowTypes';

export function collectionAlreadyCached(
  collectionCache: Array<CollectionsShowResponse>,
  collection?: CollectionsShowResponse,
  collectionId?: number,
): boolean {
  if (!collectionCache || !collection && !collectionId) return false;
  return !!collectionCache
         .map((collection: CollectionsShowResponse): number => collection.id)
         .find((cachedCollectionId: number): boolean => cachedCollectionId === collection?.id || cachedCollectionId === collectionId );
}
