import { CreditPacksCacheState } from '../../types/state/reducers/creditPacksCacheReducersTypes';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { CREDIT_PACKS_CACHE_ACTIONS } from '../../constants/actions';
const { SET_CREDIT_PACKS } = CREDIT_PACKS_CACHE_ACTIONS;

const initialState: CreditPacksCacheState = {
  creditPacks: {},
};

export const creditPacksCacheReducer = (
  state: CreditPacksCacheState = initialState,
  action: {
    type: string,
    creditPacks?: Array<CreditPackResponse>,
    license_id?: LicenseId,
  },
): CreditPacksCacheState => {
  const { type, creditPacks, license_id } = action;
  switch (type) {
    case SET_CREDIT_PACKS:
      return { creditPacks: { ...state.creditPacks, [license_id]: creditPacks } };
    default:
      return state;
  }
};
