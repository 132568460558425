import { UserVerificationState } from '../../types/state/reducers/userVerificationReducersTypes';

import { USER_VERIFICATION_ACTIONS } from './../../constants/actions';

const initialState: UserVerificationState = {
  emailSent: false,
};

export const userVerificationReducer = (
  state: UserVerificationState = initialState,
  action: {
    type: string,
    emailSent?: boolean,
  }
): UserVerificationState => {
  switch (action.type) {
    case USER_VERIFICATION_ACTIONS.SET_EMAIL_SENT:
      return { emailSent: action.emailSent || false };
    default:
      return state;
  }
}
