export default function sha256Hash(
  strings: Array<string> = [],
  successCallback: Function = () => {},
  errorCallback: Function = () => {},
): void {
  const trimmedStrings = strings.map((str: string) => str.trim());

  const errorHandler = (error: string) => {
    console.error('Error hashing:', error);
    errorCallback(error);
  }

  if (!window.crypto) {
    errorHandler('Web Crypto API not supported in this browser.');
    return;
  }

  const hashString = async (algorithm: string, text: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);

    const hashBuffer = await window.crypto.subtle.digest(algorithm, data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  };

  const hashPromises = trimmedStrings.map(text => hashString('SHA-256', text));

  Promise.all(hashPromises)
    .then(hashes => successCallback(hashes))
    .catch(errorHandler);
}
