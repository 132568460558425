import React, { useEffect, useState, useRef } from 'react';
import {
  NotificationProps,
  NotificationsProps
} from '../../types/components/shared/NotificationsTypes';
import { Notification } from '../../types/state/reducers/notificationsReducersTypes';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../state/actions/notificationsActions';
import 'animate.css';
import * as uiConstants from '../../constants/ui';
import { ApplicationState } from '../../types/state/storeTypes';
import isMobile from '../../hooks/isMobile';
import { ExitIcon } from '../../modules/Icons';

const {
  NOTIFICATION_HEIGHT_PX,
  NOTIFICATION_MARGIN_TOP_PX,
  NOTIFICATION_MOBILE_STYLE,
  NOTIFICATION_STYLE,
  NOTIFICATION_DURATION_SEC
} = uiConstants;

export const fadeoutClass = 'animate__animated animate__fadeOutDown';

const Notifications: React.FC<NotificationsProps> = (props) => {
  const { notifications } = props;
  const [innerHolderStyle, setInnerHolderStyle] = useState({});

  function animateAddNotification() {
    const bottomMarginOffset = NOTIFICATION_HEIGHT_PX + NOTIFICATION_MARGIN_TOP_PX;
    setInnerHolderStyle({ marginBottom: `-${bottomMarginOffset.toString()}px` });
    setTimeout(() => {
      setInnerHolderStyle({ transition: 'margin 350ms', marginBottom: '0' });
    }, 1);
  }

  const previosNotificationsRef = useRef(notifications);

  useEffect(() => {
    const notificationHasBeenAdded =
      previosNotificationsRef.current?.length < notifications?.length;
    previosNotificationsRef.current = notifications;

    if (!notificationHasBeenAdded) return;

    animateAddNotification();
  }, [notifications]);

  return (
    <div className="fixed w-0 h-[100vh] top-0 right-0 !z-50" cy-test-id="notifications-wrapper">
      <div className="absolute bottom-0 right-0 mb-[86px] overflow-hidden">
        <div style={innerHolderStyle}>
          {notifications.map((notification: Notification, k) => (
            <Notification key={'n' + k + '_' + notification.id} notification={notification} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Notification: React.FC<NotificationProps> = (props) => {
  const { notification } = props;

  const [useFadeoutClass, setUseFadeoutClass] = useState(false);
  const mobile = isMobile();

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(closeNotification, NOTIFICATION_DURATION_SEC * 1000);
  }, []);

  function closeNotification() {
    setUseFadeoutClass(true);
    setTimeout(() => dispatch(removeNotification(notification)), 1000);
  }

  function renderCloseNotificationButton(): React.ReactElement {
    return (
      <span
        onClick={() => closeNotification()}
        className="absolute z-[99] right-[22px] top-[2px] bg-gray-800 w-[24px] h-[24px] text-center rounded-[50%] cursor-pointer border-gray-800 hover:border-a-light-gray border-[1px]"
        cy-test-id="close-notification-button"
      >
        <ExitIcon className="scale-[0.5] -ml-[5px] -mt-[5px]"/>
      </span>
    );
  }

  if (notification.customUi)
    return (
      <div cy-test-id="custom-ui-notification" className={useFadeoutClass ? fadeoutClass : ''}>
        {renderCloseNotificationButton()}
        {notification.body}
      </div>
    );

  return (
    <div
      className={`bg-gray-800 py-2.5 px-4 text-white rounded-[6px]
        ${useFadeoutClass ? 'animate__animated animate__fadeOutDown' : ''}
      `}
      style={mobile ? NOTIFICATION_MOBILE_STYLE : NOTIFICATION_STYLE}
      cy-test-id="regular-ui-notification"
    >
      {renderCloseNotificationButton()}
      {notification.body}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  notifications: state.notifications.notifications
});
export default connect(mapStateToProps)(Notifications);
