import {
  Notification,
  NotificationsState,
  OneTimeNotificationType } from '../../types/state/reducers/notificationsReducersTypes';
import { NOTIFICATIONS_ACTIONS } from './../../constants/actions';

const initialState: NotificationsState = {
  notifications: [],
  displayedOneTimeNotifications: [],
}

export const notificationsReducer = (
  state: NotificationsState = initialState,
  action: {
    notifications?: Notification[];
    notification?: Notification;
    oneTimeNotification?: OneTimeNotificationType;
    type: string;
    // id?: number;
  }
) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.SET_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };

    case NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION:
      if (!action.notification) return state;

      const notificationType = action.notification.type;
      const existingIndex = state.notifications.findIndex(n => n.type === notificationType);

      let updatedNotifications = [...state.notifications];

      if (existingIndex !== -1) {
        updatedNotifications[existingIndex] = { ...action.notification };
      } else {
        updatedNotifications.push(action.notification);
      }

      return {
        ...state,
        notifications: updatedNotifications,
      };

    case NOTIFICATIONS_ACTIONS.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };

    case NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION:
      if (!action.notification) return state;

      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.notification.id),
      };

    case NOTIFICATIONS_ACTIONS.ONE_TIME_NOTIFICATIONS.SET_SHOWN:
      if (!action.oneTimeNotification) return state;

      return {
        ...state,
        displayedOneTimeNotifications: [...state.displayedOneTimeNotifications, action.oneTimeNotification],
      };

    default:
      return state;
  }
};
