import { BuyMoreCreditsState } from '../../types/state/reducers/buyMoreCreditsReducersTypes';
import { BuyMoreCreditsDialogMode } from '../../types/components/credits/BuyMoreCreditsDialog';

import { BUY_MORE_CREDITS_ACTIONS } from '../../constants/actions';
const { SHOW_MODAL, HIDE_MODAL } = BUY_MORE_CREDITS_ACTIONS;

const initialState: BuyMoreCreditsState = {
  showModal: false,
};

export const buyMoreCreditsReducer = (
  state = initialState,
  action: { type: string, creditsMissing?: number, modalMode?: BuyMoreCreditsDialogMode }
): BuyMoreCreditsState => {
  const { type, creditsMissing, modalMode } = action;
  switch (type) {
    case SHOW_MODAL: return { ...state, showModal: true, creditsMissing, modalMode };
    case HIDE_MODAL: return initialState;
    default: return state;
  };
};
