import { AnyAction } from 'redux';
import { newState } from '../../helpers/state/reducers/new-state';
import { DOWNLOAD_STATUS_ACTIONS } from '../../constants/actions';
import { DownloadStatusState } from '../../types/state/reducers/downloadStatusReducersTypes';

const {
  DOWNLOAD_STATUS_UPDATE,
  SHOW_UPGRADE_SUBSCRIPTION,
  HIDE_UPGRADE_SUBSCRIPTION,
  SHOW_DOWNLOAD_LIMIT,
  HIDE_DOWNLOAD_LIMIT,
  SHOW_DOWNLOAD_LIMIT_4K,
  HIDE_DOWNLOAD_LIMIT_4K
} = DOWNLOAD_STATUS_ACTIONS;

const initState: DownloadStatusState = {
  showUpgradeModal: false,
  downloadLimitReached: false,
  downloads: 0,
  downloadLimit: 0,
  showDownloadLimitNotification: false,
  showDownloadLimitModal: false,
  showDownloadLimitModal4K: false,
} as const;

const onShowUpgrade = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showUpgradeModal: true, showDownloadLimitModal: false });
};

const onHideUpgrade = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showUpgradeModal: false, showDownloadLimitModal: false });
};

const onShowLimit = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showDownloadLimitModal: true });
}

const onHideLimit = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showDownloadLimitModal: false });
}

const onShowLimit4K = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showDownloadLimitModal4K: true });
}
const onHideLimit4K = (state: DownloadStatusState, err?: Error): DownloadStatusState => {
  return newState<DownloadStatusState>(state, { showDownloadLimitModal4K: false });
}

const onUpdate = (state: DownloadStatusState, payload): DownloadStatusState => {
  const fresh = {...state, ...payload };
  return newState<DownloadStatusState>(state, fresh);
};


export const downloadStatusReducer = (state: DownloadStatusState = initState, action: AnyAction): DownloadStatusState => {
  const handlers = {
    [SHOW_UPGRADE_SUBSCRIPTION]: (): DownloadStatusState => onShowUpgrade(state),
    [HIDE_UPGRADE_SUBSCRIPTION]: (): DownloadStatusState => onHideUpgrade(state),
    [SHOW_DOWNLOAD_LIMIT]: (): DownloadStatusState => onShowLimit(state),
    [HIDE_DOWNLOAD_LIMIT]: (): DownloadStatusState => onHideLimit(state),
    [SHOW_DOWNLOAD_LIMIT_4K]: (): DownloadStatusState => onShowLimit4K(state),
    [HIDE_DOWNLOAD_LIMIT_4K]: (): DownloadStatusState => onHideLimit4K(state),
    [DOWNLOAD_STATUS_UPDATE]: (): DownloadStatusState => onUpdate(state, action.data),
  };

  const handler = handlers[action.type];
  return handler ? handler() : state;
};
