import { UserInterestsAnalyticsState } from '../../types/state/reducers/userInterestsAnalyticsTypes';
import { ISearchResult } from '../../types/api/UserInterestAnalyticsTypes';
import { SearchMode } from '../../types/components/user-interest-analytics/SendSearchAnalyticsPlugin';

import { USER_INTERESTS_ANALYTICS_ACTIONS } from '../../constants/actions';

const {
  CLEAR_SEARCH,
  ADD_RESULT_TO_BUFFER,
  EMPTY_BUFFER,
} = USER_INTERESTS_ANALYTICS_ACTIONS;

const initialState: UserInterestsAnalyticsState = {
  products_page_results_buffer: [],
  products_page_dropdown_results_buffer: [],
  top_nav_results_buffer: [],
  homepage_results_dropdown_buffer: [],
};

const clearSearchHandler = (
  state: UserInterestsAnalyticsState,
  mode: SearchMode,
): UserInterestsAnalyticsState => {
  switch (mode) {
    case 'productsPageSearch':
      return { ...state, products_page_results_buffer: [] };
    case 'productsPageDropdownSearch':
      return { ...state, products_page_dropdown_results_buffer: [] };
    case 'topNavSearch':
      return { ...state, top_nav_results_buffer: [] };
    case 'homepageDropdownSearch':
      return { ...state, homepage_results_dropdown_buffer: [] };
    default:
      return state;
  }
};

const addResultToBufferHandler = (
  state: UserInterestsAnalyticsState,
  mode: SearchMode,
  result: ISearchResult,
): UserInterestsAnalyticsState => {
  switch (mode) {
    case 'productsPageSearch':
      return { ...state, products_page_results_buffer: [ ...state.products_page_results_buffer, result ] };
    case 'productsPageDropdownSearch':
      return { ...state, products_page_dropdown_results_buffer: [ ...state.products_page_dropdown_results_buffer, result ] };
    case 'topNavSearch':
      return { ...state, top_nav_results_buffer: [ ...state.top_nav_results_buffer, result ] };
    case 'homepageDropdownSearch':
      return { ...state, homepage_results_dropdown_buffer: [ ...state.homepage_results_dropdown_buffer, result ] };
    default:
      return state;
  }
}

export const userInterestAnalyticsReducer = (
  state: UserInterestsAnalyticsState = initialState,
  action: {
    type: string,
    mode: SearchMode,
    result?: ISearchResult,
  },
): UserInterestsAnalyticsState => {
  const { result, type, mode } = action;
  switch (type) {
    case CLEAR_SEARCH:
    case EMPTY_BUFFER:
      return clearSearchHandler(state, mode);
    case ADD_RESULT_TO_BUFFER:
      return addResultToBufferHandler(state, mode, result);
    default:
      return state;
  };
}
