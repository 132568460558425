import { COLLECTIONS_CACHE_ACTIONS } from '../../constants/actions';
import { CollectionsShowResponse } from '../../types/api/CollectionsShowTypes';
import { CollectionsCacheState } from '../../types/state/reducers/collectionCacheReducersTypes';

import { collectionAlreadyCached } from '../../helpers/state/actions/collectionCacheActionHelpers';

const initialState = [];

export const collectionsCacheReducer = (
  state: CollectionsCacheState = { collections: initialState },
  action: {
    collections?: Array<CollectionsShowResponse>,
    collection?: CollectionsShowResponse,
    type: string,
  },
): CollectionsCacheState => {
  switch (action.type) {
    case COLLECTIONS_CACHE_ACTIONS.SET_COLLECTIONS_CACHE:
      return { collections: action.collections || [] };
    case COLLECTIONS_CACHE_ACTIONS.ADD_TO_COLLECTIONS_CACHE:
      if (collectionAlreadyCached(state.collections, action.collection)) return state;
      if (!state.collections) {
        return { collections: [ action.collection ] };
      }
      return {
        collections: [ ...state.collections, action.collection ]
      };
    default:
      return state;
  }
}
