// https://developers.facebook.com/docs/facebook-pixel/reference
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/facebook-pixel/index.d.ts

import { ENV } from './../../constants/environments';
import { PixelPurchaseParams, PixelCheckoutParams } from './../../types/shared/analytics/FacebookTypes';

export const FB_PIXEL_ID = ENV.fbPixelId;
export const DEFAULT_CONTENT_TYPE = 'product';

export const track = (eventType: string, params?: any) => {
  if (!process.browser) return;
  window.fbq('track', eventType, params);
};

export const pageview = () => {
  return track('PageView');
};

export const purchased = (params: PixelPurchaseParams) => {
  return track('Purchase', {
    ...params,
    num_items: 1,
  });
};

export const subscribed = (id, cost) => {
  return track('Subscribe', {
    subscription_id: id,
    value: cost,
    currency: 'USD'
  });
};

export const initCheckout = (params: PixelCheckoutParams) => {
  return track('InitiateCheckout', params);
};
