import { PlanVariantsCacheState } from '../../types/state/reducers/planVariantsCacheReducersTypes';
import { PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { PLAN_VARIANTS_CACHE_ACTIONS } from '../../constants/actions';
const { SET_PLAN_VARIANTS, CLEAR_PLAN_VARIANTS } = PLAN_VARIANTS_CACHE_ACTIONS;

const initialState: PlanVariantsCacheState = {};

export const planVariantsCacheReducer = (
  state: PlanVariantsCacheState = initialState,
  action: {
    type: string,
    licenseId?: LicenseId,
    planVariants?: Array<PlanVariantResponse>
  },
): PlanVariantsCacheState => {
  const { type, planVariants, licenseId } = action;
  switch (type) {
    case SET_PLAN_VARIANTS:
      return { ...state, [licenseId]: planVariants };
    case CLEAR_PLAN_VARIANTS:
      return initialState;
    default:
      return state;
  }
};
